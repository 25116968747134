import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { API_BASE_URL } from 'Config';
//import "./OTPRequest.css";

const OtpLogin = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [apiResponseData, setApiResponseData] = useState(null);

  const navigate = useNavigate();

  const otpRequestUrl = `${API_BASE_URL}/request-otp`; // OTP request URL
  const otpVerifyUrl = `${API_BASE_URL}/verify-otp`; // OTP verify URL

  // Function to handle OTP request
  const requestOtp = async () => {
    console.log('welcome rishr')
    setError('');
    setLoading(true);
    try {
      const response = await axios.post(otpRequestUrl, { email });
      console.log(response)
      // if (response.data) {
      //   setOtpSent(true);
      //   setSuccessMessage(response.data.message);
      // }
    } catch (error) {
      console.log(error)
      setError(error.response.data.error.email);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle OTP verification
  const verifyOtp = async () => {
    setError('');
    setLoading(true);
    try {
      const response = await axios.post(otpVerifyUrl, { email, otp });
      if (response.data) {
        setSuccessMessage(response.data.message);
        // setApiResponseData(response.data.user); // Set API response data
        const expirationTime = new Date().getTime() + 6 * 60 * 60 * 1000; // Current time + 2 hours in milliseconds
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('token_expiration', expirationTime);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        navigate('/session');
      }
    } catch (error) {
      setError(error.response.data.error.otp);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <h2>OTP Login</h2>
      {!otpSent ? (
        <div className="otp-request">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"  className={`form-control mb-3 ${ error ? 'is-invalid' : ''}`}
          />
           {error && <span className='text-danger'>{error}</span>}
           {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

           <div class="justify-contnent-around">
          <Button className=''   onClick={requestOtp} disabled={loading}>
            {loading ? 'Requesting OTP...' : 'Request OTP'}
          </Button>
          </div>
        </div>
      ) : (
        <div className="otp-verification">
          <label>OTP:</label>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter your OTP"
            maxLength="6" className={`form-control mb-3 ${ error ? 'is-invalid' : ''}`}
          />
           {error && <span className='text-danger'>{error}</span>}
           {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

          <Button className='btn btn-secondary' onClick={verifyOtp} disabled={loading}>
            {loading ? 'Verifying OTP...' : 'Verify OTP'}
          </Button>
        </div>
      )}

     

      {/* {apiResponseData && (
        <div className="api-response">
          <h3>API Response Data:</h3>
          <pre>{JSON.stringify(apiResponseData, null, 2)}</pre>
        </div>
      )} */}
    </div>
  );
};

export default OtpLogin;
