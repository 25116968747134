import React, { useState, useEffect, useContext } from 'react'

import '../assets/css/home.scss';
import '../assets/css/home.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FrontFooterDataContext } from 'contexts/FrontFooterDataContext';
import {
  Button,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Row,
  Card,
  CardHeader,
  CardBody,
  Col,
  CardTitle,
  Table
} from "reactstrap";
import Header from './Header/Header';

function Home(props) {
  console.log(localStorage.getItem('domainData'));
  const location = useLocation();
  const { selected } = location.state || {}; // Destructure with a fallback to prevent errors
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const { userNavigationTrackingState, userNavigationTracking } = useContext(FrontFooterDataContext);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const handleLogers = (jsonData) => {

    userNavigationTracking(jsonData)
  }

  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };

  const isStudent = user?.user_type === 'Student';

  return (
    <div >
       <Header  currentRoute={location.pathname}></Header>
      <main class="content">
        <div class="dashboard-container">
        <Link to={isStudent ? "#" : "/suit"}  style={{ opacity: isStudent ? 0.1 : '' }}>
          <div class="card-content content-container">
            <button className='text-white' disabled={ isStudent ? true : ''}
                    onClick={() => {handleLogers({url: '/suit',name: 'Suit Library'})}}
              >Suit Library</button>
          </div>
          </Link>
          <Link to="/creat-suit" style={{ opacity: isStudent ? 0.1 : '' }}>
          <div class="card-content content-container">
            <button className='text-white' disabled={ isStudent ? true : ''}
                    onClick={() => {handleLogers({url: '/creat-suit',name: 'Create suit'})}}
            >Create suit</button>
          </div>
          </Link>
          <Link to="/digital-fit" style={{ opacity: isStudent ? 0.1 : '' }}>
          <div class="card-content content-container">
            <button className='text-white' disabled={ isStudent ? true : ''}
                    onClick={() => {handleLogers({url: '/creat-suit',name: 'Edit Digital Fit'})}}
              >Edit Digital Fit</button>
          </div>
          </Link>
          <Link to="/simulate">
          <div class="card-content content-container">
            <button className='text-white'
                    onClick={() => {handleLogers({url: '/simulate',name: 'Simulate'})}}
              >Simulate</button>
          </div>
          </Link>
          <Link to="/admin/dashboard" style={{ opacity: isStudent ? 0.1 : '' }}>
          <div class="card-content content-container">
            <button className='text-white' disabled={ isStudent ? true : ''}
                    onClick={() => {handleLogers({url: '/admin/dashboard',name: 'Data Manager'})}}
              > Data Manager</button>
          </div>
          </Link>
          <Link to="/dashboard">
          <div class="card-content content-container">
            <button className='text-white' 
                    onClick={() => {handleLogers({url: '/dashboard',name: 'Dashboards'})}}
              >Dashboards</button>
          </div>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default Home;