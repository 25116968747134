import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "reactstrap";
function HelmetStats({ currentImage, headerTitle,  data  }) {
  const [showModal, setShowModal] = useState(false);


   // State for dynamic values
   
   const [temperature, setTemperature] = useState(54); // Initial temperature
   const [pressure, setPressure] = useState(60); // Initial temperature
   const [ecg, setEcg] = useState(74); // Initial temperature
   
 
   // Random value generators
   const generateRandomTemperature = () => Math.floor(Math.random() * 50 + 20); // Range: 20°C to 70°C
   const generateRandomPressure = () => Math.floor(Math.random() * 50 + 10);
   const generateRandomEcg = () => Math.floor(Math.random() * 50 + 30);
   useEffect(() => {
     // Set intervals to update values randomly
     const temperatureInterval = setInterval(() => {
       setTemperature(generateRandomTemperature());
     }, 2000); 
     const pressureInterval = setInterval(() => {
      setPressure(( generateRandomPressure));
    }, 2000);
    const ecgInterval = setInterval(() => {
      setEcg(generateRandomEcg());
    }, 2000);// Update every 2 seconds
 
     // Cleanup intervals on component unmount
     return () => {
       clearInterval(temperatureInterval);
       clearInterval(pressureInterval);
       clearInterval(ecgInterval);
     };
   }, []);

  // Determine what to display based on headerTitle
  const getGraphContent = () => {
    if (!headerTitle) return <div>No Graph Available</div>;

    switch (headerTitle.toLowerCase()) {
      case "helmet":
        return (
          <iframe
            src={`${window.location.origin}/lightning/helmet`}
            title="Helmet Graph"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
          />
        );
      case "glove":
        return (
          <iframe
            src={`${window.location.origin}/lightning/glove`}
            title="Glove Graph"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
          />
        );
      case "sensorsuit":
        return (
          <iframe
            src={`${window.location.origin}/lighting/sensor-suit`}
            title="Sensor Suit"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
          />
        );
      // Add more cases for other components
      default:
        return <div>No Graph Available</div>;
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

 
  return (
    <div>
      <div className="row h-100">
      <div
          className="col-md-8 d-flex justify-content-center align-items-center"
          style={{ height: "100%" }} // Ensures the container spans full height
        >
          <img
            src={currentImage}
            style={{ width: "auto", height: "60vh" }}
            className="suit-diagnostic-suit-img"
            alt="Helmet"
          />
        </div>
        <div className="col-md-4 d-flex flex-column">
          {/* <div className="text-white mb-2" style={{ cursor: "pointer" }}>
            <span
              style={{ fontSize: "18px", fontWeight: "bold" }}
              onClick={handleOpenModal} // Open modal on click
            >
              View Graph for {headerTitle || "Unknown"}
            </span>
          </div> */}
          <Row>
            <div className="mt-2">
                        {data.slice(0, 3).map((item, index) => (
                            <Col className="text-white mt-5" md="12">
                            <div className="flex-grow-1" style={{ marginBottom: "10px" }}>
                              <span style={{ fontSize: "18px", fontWeight: "bold", cursor: 'pointer' }} onClick={handleOpenModal} className="text-uppercase">{item.title}</span>
                              <div style={{ fontSize: "24px", fontWeight: "bold", color: "limegreen" }}>  {index === 1 ? temperature : index === 2 ? pressure : ecg} {item.value}</div>
                            </div>
                          </Col>
                        ))}
                    </div>
            
            
           
          </Row>
        </div>
      </div>

      {/* Bootstrap Modal */}
      {showModal && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-xl" role="document">
            <div
              className="modal-content card"
              style={{ backgroundColor: "#2c2f33", color: "#fff" }}
            >
              <div className="modal-header">
                <h5 className="modal-title text-white">{headerTitle || "Unknown"}</h5>
                <Button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleCloseModal}
                >
                  X
                </Button>
              </div>
              <div
                className="modal-body"
                style={{ height: "500px", width: "100%", overflow: "hidden" }}
              >
                {getGraphContent()}

                <img
                  src={`${process.env.PUBLIC_URL}/211003_Metakosmos_Logo_HOZ.png`}
                  alt="Overlay Logo"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                    width: "200px",
                    height: "auto",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "#1E2429", // Background color
                    padding: "10px", // Space around the image
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HelmetStats;
