import React from 'react'
import { Accordion, MenuList } from 'components' // Your own components
import './MetricListPanel.scss'

/**
 * This component handles a single item (with or without children).
 * If children are detected, it renders a nested Accordion
 * and calls itself recursively for each child.
 */
const RenderChild = ({
  item,
  icon,
  metricStack,
  selectedChild,
  setMetricStack,
  setMetricTiles,
  metricTiles,
}) => {
  /**
   * Handler for toggling a metric on/off.
   * 
   * - If the metric is not yet in the stack, add it.
   * - If the metric is in the stack, remove it.
   */
  const onMetricToggled = (metricName) => {
    const updatedMetricStack = [...metricStack]
    const updatedMetricTiles = [...metricTiles]

    // Check if this metric is already in the stack
    const existingIndex = metricStack.findIndex((metric) => metric.id === metricName.id)
    if (existingIndex === -1) {
      // Metric not found in the stack, so add it
      if (metricStack.length >= 6) {
        // If we already have 6 items, replace the 6th (index 5)
        updatedMetricTiles[5] = metricName
        updatedMetricStack[5] = metricName
        setMetricStack(updatedMetricStack)
      } else {
        // Otherwise, find the first numeric tile and replace it
        const findNumberIndex = metricTiles.findIndex((metric) => !isNaN(metric))
        updatedMetricTiles[findNumberIndex] = metricName
        setMetricStack([...metricStack, metricName])
      }
      setMetricTiles(updatedMetricTiles)
    } else {
      // Metric is in the stack, remove it
      const tileIndex = metricTiles.findIndex((metric) => metric?.id === metricName.id)
      const filteredStack = updatedMetricStack.filter((metric) => metric.id !== metricName.id)
      updatedMetricTiles[tileIndex] = tileIndex
      setMetricTiles(updatedMetricTiles)
      setMetricStack(filteredStack)
    }
  }

  /**
   * If there are children, recursively render them in a nested Accordion
   */
  if (item.children && item.children.length > 0) {
    return (
      <Accordion
        key={item.id}
        title={item.name}
        // icon={icon}
        // This will show the accordion indicator if this item is in `selectedChild`
        showIndicator={selectedChild.findIndex((child) => child?.id === item?.id) !== -1}
        itemClassName="custom-accordion-item"
        sectionClassName="custom-accordion-section"
      >
        {item.children.map((childItem) => (
          <RenderChild
            key={childItem.id}
            item={childItem}
            icon={icon}
            metricStack={metricStack}
            selectedChild={selectedChild}
            setMetricStack={setMetricStack}
            setMetricTiles={setMetricTiles}
            metricTiles={metricTiles}
          />
        ))}
      </Accordion>
    )
  }

  /**
   * If no children exist, render a MenuList
   */
  return (
    <MenuList
      key={item.id}
      data={[item]}         // Pass an array of 1 item to MenuList
      icon={icon}
      onSelected={onMetricToggled}
      activeItem={metricStack}
    />
  )
}

/**
 * Main panel component that renders top-level accordions
 * and uses RenderChild for deeper nested structures.
 */
export default function MetricListPanel({
  accordionData,
  getSelectedChild,
  metricStack,
  setMetricStack,
  setMetricTiles,
  metricTiles,
}) {
  return (
    <div className="metric-list-panel scrollbar">
      {accordionData.map(({ name, id, content, icon }) => {
        // Determine which children are "selected" for the top-level item
        const selectedChild = getSelectedChild(content)

        return (
          <Accordion
            key={id}
            title={name}
            icon={icon}
            showIndicator={selectedChild.length > 0}
          >
            {content.map((item) => (
              <RenderChild
                key={item.id}
                item={item}
                icon={icon}
                metricStack={metricStack}
                selectedChild={selectedChild}
                setMetricStack={setMetricStack}
                setMetricTiles={setMetricTiles}
                metricTiles={metricTiles}
              />
            ))}
          </Accordion>
        )
      })}
    </div>
  )
}
