import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>; // Display loading spinner while verifying token
  }

  return user ? children : <Navigate to="/" replace />;
};

export default ProtectedRoute;
