import React, { useState, useEffect, useRef } from 'react'
import './Kosmosuit.css'








import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { Html } from '@react-three/drei';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import {
  Button,

  Container,
  Row,
  Card,
  CardBody,
  Col,
} from "reactstrap";
import Header from 'front/Header/Header';
import { API_ASSET_URL } from 'front/Simulation/common/config';

export default function Kosmosuit() {
    const location = useLocation();
const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    extend({ OrbitControls });
    function AstroStaticModel() {
        const astroStaticModelRef = useRef();
        const astroStaticControlsRef = useRef();
        // Use useFrame directly inside the functional component body
        useFrame(() => {
          // Rotate the model continuously
          // modelRef.current.rotation.y += 0.01;
        });
      
      const { scene: scene2 } = useGLTF(`${API_ASSET_URL}/assets/models/Metakosmos.glb`);
      
        useEffect(() => {
          if (astroStaticModelRef.current) {
            astroStaticModelRef.current.position.y = -3.5; // Move down by 2 units
            astroStaticModelRef.current.scale.set(25, 25, 25); // Scale by a factor of 2 in all dimensions
    
             // Create a white material
        //   const whiteMaterial = new THREE.MeshBasicMaterial({ color: 0x606060 });
    
          // Apply the white material to all the meshes in the model
        //   scene2.traverse((child) => {
        //     if (child.isMesh) {
        //       child.material = whiteMaterial;
        //     }
        //   });
            
          }
        }, [scene2]);
        return (
          <>
            <primitive object={scene2} ref={astroStaticModelRef} />
            <orbitControls ref={astroStaticControlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
          </>
        );
      }

    function AstroWalkModel({ animate }) {
        const astroWalkModelRef = useRef();
        const astroWalkmixerRef = useRef();
        const astroWalkControlsRef = useRef();
        const { scene, animations } = useGLTF(`${API_ASSET_URL}/assets/models/Metakosmos walk.glb`);
      
        useFrame((state, deltaTime) => {
          // Access currentModel directly for the latest value
          if (animate && astroWalkmixerRef.current) {
            astroWalkmixerRef.current.update(deltaTime);
          }
        });
      
        useEffect(() => {
          // Clean up any existing mixer before creating a new one
          if (astroWalkmixerRef.current) {
            astroWalkmixerRef.current.stopAllAction();
            astroWalkmixerRef.current = null;
          }
      
          if (animations && animations.length > 0 && animate) {
            const mixer = new THREE.AnimationMixer(scene);
            const action = mixer.clipAction(animations[0]);
            action.play();
            astroWalkmixerRef.current = mixer;
          }
      
          if (astroWalkModelRef.current) {
            astroWalkModelRef.current.position.y = -2;
            astroWalkModelRef.current.scale.set(12, 12, 12);
          }
        }, [scene, animations, animate]); // Include currentModel in the dependency array
      
        return (
          <>
            <primitive object={scene} ref={astroWalkModelRef} />
            <orbitControls ref={astroWalkControlsRef} args={[useThree().camera, useThree().gl.domElement]} enableZoom={true} />
          </>
        );
      }

      const isSuitEngineer = user?.user_type === 'Suit Engineer';
      const restrictedRolesMcAndOm = ['Mission Controller', 'Ops Manager'];
      const isRestrictedRoleMcAndOm = restrictedRolesMcAndOm.includes(user?.user_type);

      const restrictedRoles = ['Astronaut', 'Astronaut Trainer','Astronaut Cadet'];
      const isRestrictedRole = restrictedRoles.includes(user?.user_type);

  return (
    <div>
{/* <Header currentRoute={location.pathname}></Header> */}
<Container fluid>
                <Row >
                    <Col md="12" className='m-2'>
                        <Row className='mr-3 mt-3'>
                            <Col className='' md="4">
                                <Link  to={
                                            // isRestrictedRole || 
                                            // isRestrictedRoleMcAndOm  || 
                                            // isSuitEngineer ? "#" 
                                            //                : 
                                                           "/summary"} 
                                        style={{
                                          opacity: 
                                                  // isRestrictedRole || 
                                                  // isRestrictedRoleMcAndOm || 
                                                  // isSuitEngineer ? 0.5 
                                                  //                : 
                                                                 1, // Visual feedback for disabled state
                                          pointerEvents: 
                                          
                                          // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "none" :
                                           "auto", // Disable interaction for Suit Engineers
                                          cursor: 
                                          // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "not-allowed" : 
                                          "pointer", // Change cursor for clarity
                                        }}>
                                <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                         <Row>
                                            <Col md="6"><img src={`${API_ASSET_URL}/assets/images/SuitConfigurationIcon.gif`} style={{width:'60px', height:"auto"}}></img></Col>
                                            <Col className='mt-3' md="6"><h6 className='text-center'>dashboard configuration </h6></Col>
                                            </Row>
                                            
                                            <Canvas className='kosmosuit-model'>
                                                <ambientLight />
                                                <AstroStaticModel />
                                                </Canvas>
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                                </Link>
                            </Col>
                            <Col className='' md="4">
                              <Link
                                    to={  
                                      
                                      // isRestrictedRoleMcAndOm ? "#" :
                                      
                                      "/suit-diagnostics"} 
                                    style={{
                                      opacity: 
                                      
                                      // isRestrictedRoleMcAndOm  ? 0.5 : 
                                      
                                      1, // Visual feedback for disabled state
                                      pointerEvents: 
                                      
                                      // isRestrictedRoleMcAndOm  ? "none" :
                                      
                                      "auto", // Disable interaction for Suit Engineers
                                      cursor:
                                      
                                      // isRestrictedRoleMcAndOm  ? "not-allowed" : 
                                      
                                      "pointer", // Change cursor for clarity
                                    }}>
                                <Card className='main-dashboard' 
                                    // style={{ background: '#14181B' }}
                                    style={{ background: `url(${API_ASSET_URL}/assets/dashboard/Diagnostic_Suit.gif)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
                                    >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                        <Row>
                                            <Col md="6"><img src={`${API_ASSET_URL}/assets/images/SuitDiagnosticsIcon.gif`} style={{width:'60px', height:"auto"}}></img></Col>
                                            <Col className='mt-3' md="6"><h6 className='text-center'>SUIT DIAGNOSTICS </h6></Col>
                                            </Row>
                                            <div className='' style={{height:'50vh'}}></div>
                                            {/* <img src={DiagnosticSuit}  className='simulation-image' alt="Placeholder Image"  /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                                </Link>
                            </Col>
                            <Col className='' md="4">
                            <Link  to={
                              
                              // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "#" :
                            
                            "/biomedical"} 
                                   style={{
                                     opacity: 
                                    //  isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? 0.5 :
                                      1, // Visual feedback for disabled state
                                     pointerEvents:
                                      // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "none" : 
                                      "auto", // Disable interaction for Suit Engineers
                                     cursor:
                                      // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "not-allowed" :
                                       "pointer", // Change cursor for clarity
                                   }}>
                                <Card className='main-dashboard' 
                                    //   style={{ background: '#14181B' }}
                                    style={{ background: `url(${API_ASSET_URL}/assets/images/BioMedical.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
                                      >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                        <Row>
                                            <Col md="6"><img src={
                                              `${API_ASSET_URL}/assets/images/BiomedicalIcon.gif`
                                            } style={{width:'60px', height:"auto"}}></img></Col>
                                            <Col className='mt-3' md="6"><h6 className='text-center'>BIOMEDICAL</h6></Col>
                                            </Row>
                                            <div className='' style={{height:'50vh'}}></div>
                                            {/* <img src={BioMedicalImage} className='simulation-image' alt="Placeholder Image" /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                                </Link>
                            </Col>
                            <Col className='' md="4">
                            <Link 
                                  to={
                                  
                                  // {isRestrictedRoleMcAndOm || isSuitEngineer ? "#" : 
                                    
                                    "/biomechanics"} 
                                  style={{
                                    opacity:
                                    // isRestrictedRoleMcAndOm || isSuitEngineer ? 0.5 :
                                     1, // Visual feedback for disabled state
                                    pointerEvents:
                                    //  isRestrictedRoleMcAndOm || isSuitEngineer ? "none" : 
                                     "auto", // Disable interaction for Suit Engineers
                                    cursor: 
                                    // isRestrictedRoleMcAndOm || isSuitEngineer ? "not-allowed" :
                                     "pointer", // Change cursor for clarity
                                  }}>
                                <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                        <Row>
                                            <Col md="6"><img src={`${API_ASSET_URL}/assets/images/BiomechanicsIcon.gif`} style={{width:'60px', height:"auto"}}></img></Col>
                                            <Col className='mt-3' md="6"><h6 className='text-center'>BIOMECHANICS</h6></Col>
                                            </Row>
                                            {/* <img src={DiagnosticSuit} className='simulation-image' alt="Placeholder Image" /> */}
                                            <Canvas className='kosmosuit-model'>
                                                <ambientLight />
                                                <AstroWalkModel animate={true} />
                                                </Canvas>
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                                </Link>
                            </Col>
                            <Col className='' md="4">
                                <Link
                                      to={
                                        
                                        // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "#" : 
                                        
                                        "/coginative"} 
                                      style={{
                                        opacity: 
                                        
                                        // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? 0.5 :
                                         1, // Visual feedback for disabled state
                                        pointerEvents: 
                                        // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "none" : 
                                        "auto", // Disable interaction for Suit Engineers
                                        cursor: 
                                        // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "not-allowed" :
                                         "pointer", // Change cursor for clarity
                                      }}>
                                <Card className='main-dashboard' 
                                    //   style={{ background: '#14181B' }}
                                      style={{ background: `url(${API_ASSET_URL}//assets/images/Cognitive.gif)`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}
                                      >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                        <Row>
                                            <Col md="6"><img src={`${API_ASSET_URL}/assets/dashboard/cognitive.gif`} style={{width:'60px', height:"auto"}}></img></Col>
                                            <Col className='mt-3' md="6"><h6 className='text-center'>COGNITIVE</h6></Col>
                                            </Row>
                                            <div className='' style={{height:'50vh'}}></div>
                                            {/* <img src={CognitiveGif} className='simulation-image' alt="Placeholder Image" /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                                </Link>
                            </Col>
                            <Col className='' md="4">
                                <Link 
                                      to={
                                        
                                        // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "#" : 
                                        
                                        "/environment"} 
                                      style={{
                                        opacity:
                                        //  isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? 0.5 :
                                          1, // Visual feedback for disabled state
                                        pointerEvents: 
                                        // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "none" : 
                                        "auto", // Disable interaction for Suit Engineers
                                        cursor: 
                                        // isRestrictedRole || isRestrictedRoleMcAndOm || isSuitEngineer ? "not-allowed" :
                                         "pointer", // Change cursor for clarity
                                      }}>
                                <Card className='main-dashboard' 
                                    //   style={{ background: '#14181B' }}
                                    style={{
                                      background: `url(${API_ASSET_URL}/assets/images/Environment.gif)`, 
                                      backgroundSize: 'cover', 
                                      backgroundPosition: 'center', 
                                      backgroundRepeat: 'no-repeat',
                                      backgroundColor: 'black' // This adds a black background color
                                    }}
                                      >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                        <Row>
                                            <Col md="6"><img src={`${API_ASSET_URL}/assets/images/EnvironmentIcon.gif`} style={{width:'60px', height:"auto"}}></img></Col>
                                            <Col className='mt-3' md="6"><h6 className='text-center'>Environment</h6></Col>
                                            </Row>
                                            <div className='' style={{height:'50vh'}}></div>
                                            {/* <img src={EnvironmentGif} className='simulation-image' alt="Placeholder Image" /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        
        </div>
  )
}
