import React, { useEffect, useRef } from 'react';

const globalSettings = {
    mediapipe: {
        modelComplexity: 1,
        smoothLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
        refineFaceLandmarks: true,
    },
    preview: {
        showSkeletonOnInput: true,
    },
};

const VideoMotionCapture = () => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        const loadScripts = async () => {
            const holisticScript = document.createElement('script');
            holisticScript.src = 'https://cdn.jsdelivr.net/npm/@mediapipe/holistic/holistic.js';
            holisticScript.async = true;

            const drawingScript = document.createElement('script');
            drawingScript.src = 'https://cdn.jsdelivr.net/npm/@mediapipe/drawing_utils/drawing_utils.js';
            drawingScript.async = true;

            document.body.appendChild(holisticScript);
            document.body.appendChild(drawingScript);

            holisticScript.onload = () => {
                const { Holistic } = window;
                const holistic = new Holistic({
                    locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/holistic/${file}`,
                });

                holistic.setOptions({
                    modelComplexity: globalSettings.mediapipe.modelComplexity,
                    smoothLandmarks: globalSettings.mediapipe.smoothLandmarks,
                    minDetectionConfidence: globalSettings.mediapipe.minDetectionConfidence,
                    minTrackingConfidence: globalSettings.mediapipe.minTrackingConfidence,
                    refineFaceLandmarks: globalSettings.mediapipe.refineFaceLandmarks,
                });

                holistic.onResults(onResults);

                const videoElement = videoRef.current;
                const guideCanvas = canvasRef.current;

                navigator.mediaDevices
                    .getUserMedia({
                        video: {
                            width: 1280,
                            height: 720,
                        },
                    })
                    .then((stream) => {
                        videoElement.srcObject = stream;
                        videoElement.play();
                        const videoFrameCallback = async () => {
                            await holistic.send({ image: videoElement });
                            videoElement.requestVideoFrameCallback(videoFrameCallback);
                        };
                        videoElement.requestVideoFrameCallback(videoFrameCallback);
                    })
                    .catch((error) => {
                        console.error('Error accessing the camera: ', error);
                    });
            };
        };

        const onResults = (results) => {
            const guideCanvas = canvasRef.current;
            const videoElement = videoRef.current;
            const canvasCtx = guideCanvas.getContext('2d');
            guideCanvas.width = videoElement.videoWidth;
            guideCanvas.height = videoElement.videoHeight;
            canvasCtx.save();
            canvasCtx.clearRect(0, 0, guideCanvas.width, guideCanvas.height);

            const { drawConnectors, drawLandmarks, POSE_CONNECTIONS, FACEMESH_TESSELATION, HAND_CONNECTIONS } = window;

            if (
                !results.poseLandmarks &&
                !results.faceLandmarks &&
                !results.leftHandLandmarks &&
                !results.rightHandLandmarks
            ) {
                console.error('No landmarks detected');
                return;
            }

            if (globalSettings.preview.showSkeletonOnInput) {
                if (results.poseLandmarks) {
                    drawConnectors(canvasCtx, results.poseLandmarks, POSE_CONNECTIONS, {
                        color: '#00cff7',
                        lineWidth: 4,
                    });
                    drawLandmarks(canvasCtx, results.poseLandmarks, {
                        color: '#ff0364',
                        lineWidth: 2,
                    });
                }
                if (results.faceLandmarks) {
                    drawConnectors(canvasCtx, results.faceLandmarks, FACEMESH_TESSELATION, {
                        color: '#C0C0C070',
                        lineWidth: 1,
                    });
                    if (results.faceLandmarks.length === 478) {
                        drawLandmarks(
                            canvasCtx,
                            [results.faceLandmarks[468], results.faceLandmarks[473]],
                            {
                                color: '#ffe603',
                                lineWidth: 2,
                            }
                        );
                    }
                }
                if (results.leftHandLandmarks) {
                    drawConnectors(canvasCtx, results.leftHandLandmarks, HAND_CONNECTIONS, {
                        color: '#eb1064',
                        lineWidth: 5,
                    });
                    drawLandmarks(canvasCtx, results.leftHandLandmarks, {
                        color: '#00cff7',
                        lineWidth: 2,
                    });
                }
                if (results.rightHandLandmarks) {
                    drawConnectors(canvasCtx, results.rightHandLandmarks, HAND_CONNECTIONS, {
                        color: '#22c3e3',
                        lineWidth: 5,
                    });
                    drawLandmarks(canvasCtx, results.rightHandLandmarks, {
                        color: '#ff0364',
                        lineWidth: 2,
                    });
                }
            }

            canvasCtx.restore();
        };

        loadScripts();

        return () => {
            // Clean up script tags when the component unmounts
            const scripts = document.querySelectorAll('script[src*="mediapipe"]');
            scripts.forEach((script) => script.remove());
        };
    }, []);

    return (
        <div>
             <video
                className="input_video"
                muted
                ref={videoRef}
                style={{
                    width: '100%',
                    height: '330px',
                    border: 'solid 1px #ccc',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    objectFit: 'cover',
                    transform: 'scale(-1, 1)',
                }}
            />
            <canvas
                className="guides"
                ref={canvasRef}
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    objectFit:'cover',
                    height: '100%',
                    transform: 'scale(-1, 1)',
                }}
            ></canvas>
        </div>
    );
};

export default VideoMotionCapture;
