import React, { useEffect, useRef } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { API_ASSET_URL } from "front/Simulation/common/config";
import VideoMotionCapture from "front/Simulation/VideoMotionCapture";

const MaleMuscleModel = () => {
  const modelRef = useRef();

  const { scene: scene1 } = useGLTF(`${API_ASSET_URL}/assets/models/Male muscle.glb`);

  useEffect(() => {
    if (modelRef.current) {
      modelRef.current.position.y = -3;
      modelRef.current.scale.set(3.5, 3.5, 3.5);
    }
  }, [scene1]);

  return <primitive object={scene1} ref={modelRef} />;
};

const AstroStaticModel = () => {
  const astroStaticModelRef = useRef();

  const { scene } = useGLTF(`${API_ASSET_URL}/assets/models/Metakosmos.glb`);

  useEffect(() => {
    if (astroStaticModelRef.current) {
      astroStaticModelRef.current.position.set(0, -3, 0);
      astroStaticModelRef.current.scale.set(23, 23, 23);
    }
  }, [scene]);

  const clonedScene = scene.clone();

  return <primitive object={clonedScene} ref={astroStaticModelRef} />;
};

const GridLayoutWithModels = () => {
  return (
    <Container fluid className="">
      {/* Header Row */}
      <Row className="text-center">
        <Col>
          <h3>Kosmosuit Motion</h3>
        </Col>
        <Col>
          <h3>Metakosmos Lab</h3>
        </Col>
        <Col>
          <h3>Musculoskeletal</h3>
        </Col>
      </Row>

      {/* First Row: Models */}
      <Row className="text-center">
        <Col md={4} >
          <Card style={{ height: '350px' }}>
            <CardBody>
              <Canvas>
                <ambientLight intensity={0.5} />

                {/* Add directional light for focused lighting */}
                <directionalLight position={[5, 5, 5]} intensity={1} />

                {/* Optional: Add a point light for a glowing effect */}
                <pointLight position={[-5, 5, -5]} intensity={0.8} color="white" />
                <AstroStaticModel />
                <OrbitControls enableZoom={true} />
              </Canvas>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} >
          <Card style={{ height: '350px' }}>
            <CardBody>
              <VideoMotionCapture/>
              {/* Add content for the side view or additional model here */}
            </CardBody>
          </Card>
        </Col>
        <Col md={4} >
          <Card style={{ height: '350px' }}>
            <CardBody>
              <Canvas>
                <ambientLight intensity={0.5} />

                {/* Add directional light for focused lighting */}
                <directionalLight position={[5, 5, 5]} intensity={1} />

                {/* Optional: Add a point light for a glowing effect */}
                <pointLight position={[-5, 5, -5]} intensity={0.8} color="white" />
                <MaleMuscleModel />
                <OrbitControls enableZoom={true} />
              </Canvas>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Second Row: View Labels */}
      {/* <Row className="text-center mb-4">
        <Col className="">
          <h5>Front View</h5>
        </Col>
        <Col>
          <h5>Side View</h5>
        </Col>
        <Col>
          <h5>Top View</h5>
        </Col>
      </Row> */}

      {/* Second Row: Models */}
      <Row className="text-center mb-4">
        <Col md={4} className="" >
          <Card style={{ height: '350px' }}>
            <CardHeader><h5>Front View</h5></CardHeader>
            <CardBody>
              <Canvas>
                <ambientLight intensity={0.5} />

                {/* Add directional light for focused lighting */}
                <directionalLight position={[5, 5, 5]} intensity={1} />

                {/* Optional: Add a point light for a glowing effect */}
                <pointLight position={[-5, 5, -5]} intensity={0.8} color="white" />
                <AstroStaticModel />
                <OrbitControls enableZoom={true} />
              </Canvas>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} className="" >
          <Card style={{ height: '350px' }}>
            <CardHeader><h5>Side View</h5></CardHeader>
            <CardBody>
              <Canvas camera={{ position: [9, 0, 0], fov: 45 }}>
                <ambientLight intensity={0.5} />

                {/* Add directional light for focused lighting */}
                <directionalLight position={[5, 5, 5]} intensity={1} />

                {/* Optional: Add a point light for a glowing effect */}
                <pointLight position={[-5, 5, -5]} intensity={0.8} color="white" />
                <AstroStaticModel />
                <OrbitControls enableZoom={true} />
              </Canvas>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} className="" >
          <Card style={{ height: '350px' }}>
            <CardHeader><h5>Top View</h5></CardHeader>
            <CardBody>
              <Canvas camera={{ position: [0, 5, 0], fov: 45 }}>
                <ambientLight intensity={0.5} />

                {/* Add directional light for focused lighting */}
                <directionalLight position={[5, 5, 5]} intensity={1} />

                {/* Optional: Add a point light for a glowing effect */}
                <pointLight position={[-5, 5, -5]} intensity={0.8} color="white" />
                <AstroStaticModel />
                <OrbitControls enableZoom={true} />
              </Canvas>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GridLayoutWithModels;
