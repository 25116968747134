import React, { useEffect,useContext } from 'react';
import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { FrontFooterDataContext } from 'contexts/FrontFooterDataContext';
import { Button } from 'reactstrap';
import './TrackingCarousel.css'; 

const TrackingCarousel = () => {
  const planets = ['Mercury', 'Venus', 'Earth', 'Mars', 'Saturn', 'Moon'];
  const { userNavigationTrackingState, userNavigationTracking } = useContext(FrontFooterDataContext);

console.log(userNavigationTrackingState);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  const groupedItems = userNavigationTrackingState.reduce((accumulator, currentValue, index) => {
    if (index % 5 === 0) {
      accumulator.push([currentValue]);
    } else {
      accumulator[accumulator.length - 1].push(currentValue);
    }
    return accumulator;
  }, []);

  return (
    <div>
      {userNavigationTrackingState.length ? (
        <Carousel className='m-3'
        showArrows={true} // Enable arrows 
        >
          
          {groupedItems.map((group, i) => (
            <div key={i} className="carousel-slide">
              {group.map((item, j) => (
                <Link to={item.url} key={j} className='text-white' style={{marginRight: '15px'}}>
                  {item.name}
                  <i className="ml-2 fa fa-angle-right" style={{fontSize:'12px'}}></i>
                  {/* <Button className='btn btn-default' style={{ background: '#101f2a', border: '1px solid #01426A'}}>
                    {item.name}
                  </Button> */}
                </Link>
              ))}
            </div>
          ))}
        </Carousel>
      ) : (
        <h6 className='mt-3 text-center'>Not Found</h6>
      )}
    </div>
  );
};

export default TrackingCarousel;
