import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const PdfViewer = ({ file, visiblePages }) => {
    const [numPages, setNumPages] = useState(null);
    const [zoom, setZoom] = useState(1); // Default zoom level
    const [scale, setScale] = useState(1.5); // Default scale


    useEffect(() => {
        const updateScale = () => {
            const width = window.innerWidth;
            if (width <= 768) setScale(0.5); // Smaller scale for mobile
            else if (width <= 1024) setScale(0.83); // Medium scale for tablets
            else if (width <= 1440) setScale(1.3); // Default scale for desktops
            else setScale(1.8); // Larger scale for bigger screens
        };

        // Update scale on component mount and window resize
        updateScale();
        window.addEventListener('resize', updateScale);

        return () => window.removeEventListener('resize', updateScale);
    }, []);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleZoomIn = () => {
        setZoom(prevZoom => prevZoom + 0.25); // Increase zoom by 0.25
    };

    const handleZoomOut = () => {
        setZoom(prevZoom => Math.max(0.25, prevZoom - 0.25)); // Decrease zoom but ensure zoom is at least 0.25
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* Zoom Controls */}
            {/* <div>
                <button onClick={handleZoomIn}>Zoom In</button>
                <button onClick={handleZoomOut}>Zoom Out</button>
            </div> */}
            
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                {visiblePages.map((pageNum) => (
                    <Page key={pageNum} pageNumber={pageNum} scale={scale} />
                ))}
            </Document>
        </div>
    );
};

export default PdfViewer;
