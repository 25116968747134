import SciChartExample from 'front/SciCharts/SciChartExample';
import SciChartsExampleOne from 'front/SciCharts/SciChartsExampleOne';
import SciChartsExampleTwo from 'front/SciCharts/SciChartsExampleTwo';
import SciChartExampleThree from 'front/SciCharts/SciChartExampleThree';
import SciChartsExampleFour from 'front/SciCharts/SciChartsExampleFour';
import SciChartsExampleFive from 'front/SciCharts/SciChartsExampleFive';
import React, { useContext, useEffect, useState ,  useLocation } from 'react';
import HumanDataContext from "contexts/HumanDataContext";
import Stopwatch from 'front/Stopwatch';
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table,
    ModalBody
} from "reactstrap";
import { API_ASSET_URL } from 'front/Simulation/common/config';

function SecondDashboard(props) {
    localStorage.getItem('domainData')

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

    const [domainData, setDomainData] = useState('');

    useEffect(() => {
      // Retrieve 'domainData' from localStorage when the component mounts
      const storedDomainData = localStorage.getItem('domainData');
      if (storedDomainData) {
        setDomainData(storedDomainData);
      }
    }, []);



    const messages = [
        { sender: 'MCR', content: 'How do you feel' },
        { sender: 'Suit ID', content: 'I am OK' },
        { sender: 'MCR', content: 'Have you spotted any aliens yet ??' },
        { sender: '', content: 'HELP HELP' },
    ];

    const { data } = useContext(HumanDataContext);
    const [timeCode, setTimeCode] = useState({ hours: 0, minutes: 0, seconds: 0, frames: 0 });

    function renderOptions(metrics) {
        <option selected> Select Metric</option>
        return metrics.map((metric) => (
            <option>{metric.metric}</option>
        ));
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeCode((prevTimeCode) => {
                let { hours, minutes, seconds, frames } = prevTimeCode;
                frames++;
                if (frames >= 25) {
                    frames = 0;
                    seconds++;
                    if (seconds >= 60) {
                        seconds = 0;
                        minutes++;
                        if (minutes >= 60) {
                            minutes = 0;
                            hours++;
                        }
                    }
                }
                return { hours, minutes, seconds, frames };
            });
        }, 40); // 1000 ms / 25 fps = 40 ms per frame
        return () => clearInterval(interval);
    }, []);

    const formatTime = (unit) => unit.toString().padStart(2, '0');

    const handleOpenNewTab = () => {
        const newTab = window.open();
        newTab.location.href = `/earth/?type=${localStorage.getItem('domainData')}`; // Opens the '/earth' route in a new tab
    };


    const isSuitEngineer = user?.user_type === 'Suit Engineer';

    const restrictedRoles = ['Astronaut', 'Astronaut Trainer','Astronaut Cadet'];
      const isRestrictedRole = restrictedRoles.includes(user?.user_type);

    return (
        <div style={{ backgroundColor: '#191f24' }}>
            <Container fluid>
                {/* <Row>
                <Col md="12" className='mr-3 mt-5'>
                    <Row className='mr-3 mt-3'>
                        <Col className='' md="4">
                            <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                <div className="inner_dashboard">
                                    <span className="corner-top-left"></span>
                                    <span className="corner-top-right"></span>
                                    <CardBody style={{ background: '#14181B' }}>
                                    <Row className='mb-3'>
                                            <Col md="6" lg="6">
                                                <select className='form-control'>
                                                    <option>Data Manager</option>
                                                    <option>Digital Fit</option>
                                                    <option>Simulation</option>
                                                    <option>Pre Flight</option>
                                                </select>
                                            </Col>
                                            <Col md="6" lg="6">
                                                <select className='form-control' style={{width:'150% !important'}}>
                                                {renderOptions(data.metrics)}
                                                </select>
                                            </Col>
                                        </Row>
                                       <SciChartExample />
                                    </CardBody>
                                    <span className="corner-bottom-left"></span>
                                    <span className="corner-bottom-right"></span>
                                </div>
                            </Card>
                        </Col>
                        <Col className='' md="4">
                            <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                <div className="inner_dashboard">
                                    <span className="corner-top-left"></span>
                                    <span className="corner-top-right"></span>
                                    <CardBody style={{ background: '#14181B' }}>
                                    <Row className='mb-3'>
                                            <Col md="6" lg="6">
                                                <select className='form-control'>
                                                    <option>Data Manager</option>
                                                    <option>Digital Fit</option>
                                                    <option>Simulation</option>
                                                    <option>Pre Flight</option>
                                                </select>
                                            </Col>
                                            <Col md="6" lg="6">
                                                <select className='form-control' style={{width:'150% !important'}}>
                                                {renderOptions(data.metrics)}
                                                </select>
                                            </Col>
                                        </Row>
                                    <SciChartsExampleOne />
                                    </CardBody>
                                    <span className="corner-bottom-left"></span>
                                    <span className="corner-bottom-right"></span>
                                </div>
                            </Card>
                        </Col>
                        <Col className='' md="4">
                            <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                <div className="inner_dashboard">
                                    <span className="corner-top-left"></span>
                                    <span className="corner-top-right"></span>
                                    <CardBody style={{ background: '#14181B' }}>
                                    <Row className='mb-3'>
                                            <Col md="6" lg="6">
                                                <select className='form-control'>
                                                    <option>Data Manager</option>
                                                    <option>Digital Fit</option>
                                                    <option>Simulation</option>
                                                    <option>Pre Flight</option>
                                                </select>
                                            </Col>
                                            <Col md="6" lg="6">
                                                <select className='form-control' style={{width:'150% !important'}}>
                                                {renderOptions(data.metrics)}
                                                </select>
                                            </Col>
                                        </Row>
                                    <SciChartsExampleTwo />
                                    </CardBody>
                                    <span className="corner-bottom-left"></span>
                                    <span className="corner-bottom-right"></span>
                                </div>
                            </Card>
                        </Col>
                        <Col className='' md="4">
                            <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                <div className="inner_dashboard">
                                    <span className="corner-top-left"></span>
                                    <span className="corner-top-right"></span>
                                    <CardBody style={{ background: '#14181B' }}>
                                    <Row className='mb-3'>
                                            <Col md="6" lg="6">
                                                <select className='form-control'>
                                                    <option>Data Manager</option>
                                                    <option>Digital Fit</option>
                                                    <option>Simulation</option>
                                                    <option>Pre Flight</option>
                                                </select>
                                            </Col>
                                            <Col md="6" lg="6">
                                                <select className='form-control' style={{width:'150% !important'}}>
                                                {renderOptions(data.metrics)}
                                                </select>
                                            </Col>
                                        </Row>
                                    <SciChartExampleThree />
                                    </CardBody>
                                    <span className="corner-bottom-left"></span>
                                    <span className="corner-bottom-right"></span>
                                </div>
                            </Card>
                        </Col>
                        <Col className='' md="4">
                            <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                <div className="inner_dashboard">
                                    <span className="corner-top-left"></span>
                                    <span className="corner-top-right"></span>
                                    <CardBody style={{ background: '#14181B' }}>
                                    <Row className='mb-3'>
                                            <Col md="6" lg="6">
                                                <select className='form-control'>
                                                    <option>Data Manager</option>
                                                    <option>Digital Fit</option>
                                                    <option>Simulation</option>
                                                    <option>Pre Flight</option>
                                                </select>
                                            </Col>
                                            <Col md="6" lg="6">
                                                <select className='form-control' style={{width:'150% !important'}}>
                                                {renderOptions(data.metrics)}
                                                </select>
                                            </Col>
                                        </Row>
                                    <SciChartsExampleFour />
                                    </CardBody>
                                    <span className="corner-bottom-left"></span>
                                    <span className="corner-bottom-right"></span>
                                </div>
                            </Card>
                        </Col>
                        <Col className='' md="4">
                            <Card className='main-dashboard' style={{ background: '#14181B' }}>
                                <div className="inner_dashboard">
                                    <span className="corner-top-left"></span>
                                    <span className="corner-top-right"></span>
                                    <CardBody style={{ background: '#14181B' }}>
                                    <Row className='mb-3'>
                                            <Col md="6" lg="6">
                                                <select className='form-control'>
                                                    <option>Data Manager</option>
                                                    <option>Digital Fit</option>
                                                    <option>Simulation</option>
                                                    <option>Pre Flight</option>
                                                </select>
                                            </Col>
                                            <Col md="6" lg="6">
                                                <select className='form-control' style={{width:'150% !important'}}>
                                                {renderOptions(data.metrics)}
                                                </select>
                                            </Col>
                                        </Row>
                                    <SciChartsExampleFive />
                                    </CardBody>
                                    <span className="corner-bottom-left"></span>
                                    <span className="corner-bottom-right"></span>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row> */}

<Row>
  <Col md="12">
    <Row className="mt-3">
          {/* Camera View Card */}
          <Col md="4">
            <Card
              className="main-dashboard"
              style={{
                backgroundImage: `url(${API_ASSET_URL}/assets/images/camera_view.gif)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                display: 'flex',
              }}
            >
              <div className="inner_dashboard">
                <span className="corner-top-left"></span>
                <span className="corner-top-right"></span>
                <CardBody>
                  <h6 className="text-center">Camera View</h6>
                  {/* <img src={Cameraviewgif} className='simulation-image' alt="Camera View" /> */}
                </CardBody>
                <span className="corner-bottom-left"></span>
                <span className="corner-bottom-right"></span>
              </div>
            </Card>
          </Col>

                            <Col className='' md="4">
                                <Card
                                    className="main-dashboard"
                                    style={{
                                        backgroundImage: `url(${API_ASSET_URL}/assets/images/fleet_view.png)`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        width: '100%',
                                        display: 'flex',
                                        opacity: isSuitEngineer ? 0.5 : 1, // Reduce opacity when disabled
                                        pointerEvents: isSuitEngineer ? 'none' : 'auto', // Disable interactions when user is a Suit Engineer
                                        cursor: isSuitEngineer ? 'not-allowed' : 'pointer', // Change cursor to indicate non-clickable
                                    }}
                                    onClick={isSuitEngineer ? undefined : handleOpenNewTab} // Disable onClick for Suit Engineer
                                >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody
                                        //   style={{ background: '#14181B' }}
                                        >
                                            <h6 className='text-center'>Fleet View</h6>
                                            {/* <img src={FleetViewImage} className='simulation-image' alt="Fleet View" /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>

          {/* Astronaut Position System (APS) Card */}
          <Col md="4">
            <Card
              className="main-dashboard"
              style={{
                backgroundImage: `url(${API_ASSET_URL}/assets/dashboard/motion-gifs/walk-4fps-V1.gif)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                display: 'flex',
              }}
            >
              <div className="inner_dashboard">
                <span className="corner-top-left"></span>
                <span className="corner-top-right"></span>
                <CardBody>
                  <h6 className="text-center">Astronaut Position System (APS)</h6>
                  {/* <img src={MapPositioningImage} className='simulation-image' alt="Map Positioning" /> */}
                </CardBody>
                <span className="corner-bottom-left"></span>
                <span className="corner-bottom-right"></span>
              </div>
            </Card>
          </Col>
      
          {/* Caution Warning System Card */}
          <Col md="4">
            <Card
              className="main-dashboard"
              style={{
                backgroundImage: `url(${API_ASSET_URL}/assets/images/caution_warning_system.gif)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '99% 99%', // Adjust as needed
                backgroundPosition: 'center',
                width: '100%',
                display: 'flex',
              }}
            >
              <div className="inner_dashboard">
                <span className="corner-top-left"></span>
                <span className="corner-top-right"></span>
                <CardBody>
                  <h6 className="text-center">Caution Warning System</h6>
                  {/* <img src={CautionWarningSystemImage} className='simulation-image' alt="Caution Warning System" /> */}
                </CardBody>
                <span className="corner-bottom-left"></span>
                <span className="corner-bottom-right"></span>
              </div>
            </Card>
          </Col>

          {/* TimeCode Monitor Card */}
          <Col md="4">
            <Card className="main-dashboard" style={{ background: '#14181B', position: 'relative' }}>
              <div className="inner_dashboard" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                <span className="corner-top-left"></span>
                <span className="corner-top-right"></span>
                <Card className="timeline-card" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, height: '100%' }}>
                  <CardHeader className="" style={{ background: '#585758', borderRadius: '15px' }}>
                    <div className="traffic-lights">
                      <span className="light red"></span>
                      <span className="light yellow"></span>
                      <span className="light green"></span>
                    </div>
                    <p className="time-code text-center">TimeCode Monitor</p>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="4">
                        <button>HISTORY</button>
                        <button>25</button>
                      </Col>
                      <Col md="4">
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '2px', backgroundColor: 'black', padding: '5px' }}>
                          {[...Array(10)].map((_, i) => (
                            <div key={i} style={{ width: '5px', height: '20px', backgroundColor: i === 0 ? 'white' : '#555' }} />
                          ))}
                        </div>
                      </Col>
                      <Col md="4">
                        <button>ABOUT</button>
                        <button>CONFIG</button>
                      </Col>
                    </Row>
                    <Col className="mt-3 text-center">
                      <div className="timecode-display">
                        {formatTime(timeCode.hours)}:{formatTime(timeCode.minutes)}:{formatTime(timeCode.seconds)}:{formatTime(timeCode.frames)}
                      </div>
                    </Col>
                  </CardBody>
                </Card>
                <span className="corner-bottom-left"></span>
                <span className="corner-bottom-right"></span>
              </div>
            </Card>
          </Col>

          {/* Telemetry Signal Card */}
          <Col md="4">
            <Card className="main-dashboard" style={{ background: '#14181B' }}>
              <div className="inner_dashboard">
                <span className="corner-top-left"></span>
                <span className="corner-top-right"></span>
                <h6 className="text-center">Telemetry Signal</h6>
                <CardBody>
                  <Stopwatch />
                </CardBody>
                <span className="corner-bottom-left"></span>
                <span className="corner-bottom-right"></span>
              </div>
            </Card>
          </Col>

          {/* Space Sortie Card */}
          <Col md="4">
            <Card
              className="main-dashboard"
              style={{
                backgroundImage: `url(${API_ASSET_URL}/assets/images/ProcedureView.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                display: 'flex',
                backgroundBlendMode: 'soft-light',
              }}
            >
              <div className="inner_dashboard">
                <span className="corner-top-left"></span>
                <span className="corner-top-right"></span>
                <CardBody>
                  <h6 className="text-center">Space Sortie</h6>
                  {/* <img src={ProcedureViewImage} className='simulation-image' alt="Procedure View" /> */}
                </CardBody>
                <span className="corner-bottom-left"></span>
                <span className="corner-bottom-right"></span>
              </div>
            </Card>
          </Col>

          {/* Two-Way Audio Card */}
          <Col md="4">
            <Card
              className="main-dashboard"
              style={{
                backgroundImage: `url(${API_ASSET_URL}/assets/images/TwoWayAudio.gif)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '99% 99%', // Adjust as needed
                backgroundPosition: 'center',
                width: '100%',
                display: 'flex',
              }}
            >
              <div className="inner_dashboard">
                <span className="corner-top-left"></span>
                <span className="corner-top-right"></span>
                <CardBody>
                  <h6 className="text-center">2 – Way Audio</h6>
                  {/* <img src={TwoWayAudioImage} className='simulation-image' alt="2 – Way Audio" /> */}
                </CardBody>
                <span className="corner-bottom-left"></span>
                <span className="corner-bottom-right"></span>
              </div>
            </Card>
          </Col>

          {/* Messaging Card */}
          <Col md="4">
            <Card className="main-dashboard" style={{ background: '#14181B' }}>
              <div className="inner_dashboard">
                <span className="corner-top-left"></span>
                <span className="corner-top-right"></span>
                <h6 className="text-center">Messaging</h6>
                <CardBody>
                  <Row className="messaging" style={{ paddingTop: '25px' }}>
                    <Col>
                      <div className="messaging-display">
                        {messages.map((message, index) => (
                          <div key={index} style={{ color: 'lime' }}>
                            <Row>
                              <Col md="6">
                                <h5
                                  className={message.sender ? 'typing-animation' : ''}
                                  style={index % 2 === 0 ? { color: '#0f0' } : {}}
                                >
                                  {message.sender}
                                </h5>
                              </Col>
                              <Col md="6">
                                <h5
                                  className="typing-animation"
                                  style={index % 2 === 0 ? { color: '#0f0' } : {}}
                                >
                                  {message.content}
                                </h5>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <span className="corner-bottom-left"></span>
                <span className="corner-bottom-right"></span>
              </div>
            </Card>
          </Col>
    </Row>
  </Col>
</Row>

            </Container>

        </div>
    );
}

export default SecondDashboard;