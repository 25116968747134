import React from 'react'
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiDataComponent } from './Apicall';
import Header from 'front/Header/Header';
import Footer from 'front/Footer/Footer';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Row,
  Card,
  CardHeader,
  CardBody,
  Col,
  CardTitle,
  CardText,
  Table,
  ModalBody
} from "reactstrap";

import SuitDiagnosticsData from './SuitDiagnostics.json';
import './SuitDiagnostics.css'
import SpeedoMeter from './SpeedoMeter';
import HelmetModelView from 'front/CreatSuit/3DModels/HelmetModelView';
import ModelViewer from './Mertric_Charts/Helmet';
import BootDashboard from './MetricDashboards/BootDashboard';
import GloveDashboard from './MetricDashboards/GloveDashboard';
import HelmetDashboard from './MetricDashboards/HelemtDashboard';
import LifeSupportDashboard from './MetricDashboards/LifeSupportDashboard';
import ExoskeletonDashboard from './MetricDashboards/ExoskeletonDashboard';
import BladderDashboard from './MetricDashboards/BladderDashboard';
import SensorsuitDashboard from './MetricDashboards/SensorsuitDashboard';
import ExosuitDashboard from './MetricDashboards/Exosuitdashboard';
import DashboardDropdowns from './MetricDashboards/DashboardDropdowns ';
import LeftColoumn from './SuitDiagnosticsleftside/LeftColoumn';
import HelmetStats from './SuitDiagnosticsleftside/MetricStats';
import StatusList from './SuitDiagnosticsleftside/StatusList';
import { API_ASSET_URL } from 'front/Simulation/common/config';

export default function SuitDiagnostics() {
  const [headerTitle, setHeaderTitle] = useState("FULL SUIT SYSTEM OVERVIEW");
  const [currentImage, setCurrentImage] = useState("https://bioastroause.blob.core.windows.net/suit/vyom/vyom_(2x3).png");
  const [connectionData, setConnectionData] = useState(SuitDiagnosticsData.connections);
  const [showModal, setShowModal] = useState(false);
  const [graphData, setGraphData] = useState({});
  const location = useLocation();

  const handleFooterNavClick = (text, data, imagePath) => {
    console.log(imagePath)
    setHeaderTitle(text);
    setConnectionData(data);
    setCurrentImage(imagePath);
  };

  // Function to handle the APIMODEL button click
  const handleApiModalClick = () => {
    setShowModal(true); // Open the API modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the API modal
  };


  // Handle graph update
  const updateGraph = (graphNumber, metric, graphType) => {

  };

  const handleUpdateGraph = (number, metric, graphType) => {
    setGraphData({ number, metric }); // Update graphData with the selected values

  };


  return (

    <div>
      <Header currentRoute={location.pathname}></Header>
      <Container fluid className="d-flex flex-column" style={{ height: '100vh' }}>
        <header className='text-center mt-2'>
          <h3 className='' style={{fontWeight: "bold",}}> {headerTitle}</h3>
        </header>
        <Row className="flex-grow-1 overflow-hidden">
          <Col md="2" sm="2" lg="2" className="h-100">
            <LeftColoumn />
          </Col>
          <Col md="7" className="h-100">
            <HelmetStats currentImage={currentImage} headerTitle={headerTitle} data={connectionData} />
          </Col>
          <Col md="3" className="h-100 d-flex flex-column">
            <DashboardDropdowns data={connectionData} onUpdate={handleUpdateGraph} className="flex-grow-1 overflow-auto" />
            <div tyle={{ maxHeight: '40vh', overflowY: 'auto' }}>
              <StatusList className="" data={connectionData} />
            </div>
          </Col>
        </Row>
        <Row >
          <Col className='' md="12">
            <Card className=''>
              <Row>
                {SuitDiagnosticsData.footerNav.map((footer, index) => (
                  // <Row>
                  <Col className='1'>
                    <Row>
                      <Col md="12"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleFooterNavClick(footer.text, footer.data, `${API_ASSET_URL}/assets/images/${footer.image}`)}>
                        {/* <i width={100} height={100} class={`text-white ${footer.icon}`}></i> */}
                        <img src={`${API_ASSET_URL}/assets/images/${footer.image}`} width={100} height={100} alt={footer.text} className="zoom-effect" />
                      </Col>
                      <Col md="12">
                        <p className='text-center' href={footer.href}>{footer.text}</p>
                      </Col>
                    </Row>
                  </Col>
                ))}

              </Row>

            </Card>
          </Col>
        </Row>
      </Container>
      <ApiDataComponent show={showModal} handleClose={handleCloseModal} />
      <Footer></Footer>
    </div>
  )
}
