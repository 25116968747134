import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { color } from "echarts";

const JointKineticsCharts = () => {
  // Generate smooth sinusoidal-like data that better represents biomechanical patterns
  const generateBiomechanicalData = (jointType, dataType) => {
    const length = 101;
    const data = new Array(length).fill(0);
    
    // Different patterns for different joints and data types
    switch(jointType) {
      case "Neck":
        return data.map((_, i) => {
          const x = i / 100;
          return dataType === "torque" 
            ? Math.sin(x * Math.PI * 2) * 0.8 - Math.sin(x * Math.PI) * 0.3
            : Math.cos(x * Math.PI * 2) * 1.5 - Math.sin(x * Math.PI * 3) * 0.5;
        });
      case "Shoulder":
        return data.map((_, i) => {
          const x = i / 100;
          return dataType === "torque"
            ? Math.sin(x * Math.PI * 1.5) * 0.7 - Math.cos(x * Math.PI * 2) * 0.4
            : Math.sin(x * Math.PI * 2) * 2 - Math.cos(x * Math.PI * 1.5) * 2.5;
        });
      case "Elbow":
        return data.map((_, i) => {
          const x = i / 100;
          return dataType === "torque"
            ? Math.sin(x * Math.PI * 0.8) * 1.2 + Math.sin(x * Math.PI * 2) * 0.3
            : Math.cos(x * Math.PI) * 3 + Math.sin(x * Math.PI * 2) * 1;
        });
      case "Hip":
        return data.map((_, i) => {
          const x = i / 100;
          return dataType === "torque"
            ? Math.sin(x * Math.PI * 1.2) * 1.5 - Math.cos(x * Math.PI * 0.8) * 0.5
            : Math.sin(x * Math.PI * 1.5) * 2.5 + Math.cos(x * Math.PI * 2) * 0.8;
        });
      case "Knee":
        return data.map((_, i) => {
          const x = i / 100;
          return dataType === "torque"
            ? -Math.sin(x * Math.PI * 1.3) * 1.2 + Math.cos(x * Math.PI * 0.7) * 0.4
            : Math.cos(x * Math.PI * 1.8) * 2 - Math.sin(x * Math.PI * 1.2) * 1.5;
        });
      case "Ankle":
        return data.map((_, i) => {
          const x = i / 100;
          return dataType === "torque"
            ? Math.sin(x * Math.PI * 2) * 1.2
            : Math.cos(x * Math.PI * 2) * 2;
        });
    }
  };

  // Function to generate random speeds
  const generateRandomSpeeds = () => {
    const baseSpeed = 0.5;
    const maxSpeed = 2.6;
    const numSpeeds = 5;
    const speeds = [];
    
    for (let i = 0; i < numSpeeds; i++) {
      const randomSpeed = baseSpeed + (Math.random() * (maxSpeed - baseSpeed));
      speeds.push(Number(randomSpeed.toFixed(1)));
    }
    
    // Sort speeds in ascending order
    return speeds.sort((a, b) => a - b);
  };

  // Generate multiple series with slight variations
  const generateMultipleSeries = (jointType, dataType) => {
    const baseLine = generateBiomechanicalData(jointType, dataType);
    const speeds = generateRandomSpeeds();
    // const speeds = [0.5, 1.1, 1.6, 2.1, 2.6]; // Updated speeds to match image
    
    return speeds.map(speed => {
      return baseLine.map(val => val * (speed / 1.5 + Math.random() * 0.1));
    });
  };

  // Initial state setup
  const [chartsData, setChartsData] = useState(() => {
    const joints = ["Neck", "Shoulder", "Elbow", "Hip", "Knee", "Ankle"];
    return joints.map(joint => ({
      name: joint,
      torqueData: generateMultipleSeries(joint, "torque"),
      powerData: generateMultipleSeries(joint, "power"),
      torqueYAxis: { min: -1.5, max: 1.5 },
      powerYAxis: { min: -4, max: 4 },
    }));
  });

  // Update data periodically
  useEffect(() => {
    const interval = setInterval(() => {
      setChartsData(prevData =>
        prevData.map(chart => ({
          ...chart,
          torqueData: generateMultipleSeries(chart.name, "torque"),
          powerData: generateMultipleSeries(chart.name, "power"),
        }))
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const generateChartOptions = (title, yAxisLabel, data, yAxisRange, showTakeoff = false) => ({
    title: {
      text: title,
      left: "center",
      textStyle: { fontSize: 10, fontWeight: 'normal' },
    },
    xAxis: {
      type: "category",
      data: Array.from({ length: 101 }, (_, i) => i),
      boundaryGap: false,
      axisLabel: {
        formatter: "{value}%",
        textStyle: { fontSize: 8 },
      },
      axisLine: { lineStyle: { color: "#aaa" } },
    },
    yAxis: {
      type: "value",
      name: yAxisLabel,
      nameLocation: "middle",
      nameGap: 25,
      min: yAxisRange.min,
      max: yAxisRange.max,
      nameTextStyle: { fontSize: 8 },
      axisLabel: { textStyle: { fontSize: 8 } },
      axisLine: { lineStyle: { color: "#aaa" } },
      splitLine: { lineStyle: { type: "dashed", color: "#ddd" } },
    },
    series: [
      ...data.slice(0, -1).map((values, index) => ({
        type: "line",
        smooth: true,
        data: values,
        lineStyle: {
          width: 1,
          color: '#999',
          opacity: 0.5,
        },
        symbol: 'circle',
        symbolSize: 2,
        name: `${[0.5, 1.1, 1.6, 2.1][index]}m/s`,
      })),
      {
        type: "line",
        smooth: true,
        data: data[data.length - 1],
        lineStyle: {
          width: 2,
          color: "#000",
        },
        symbol: 'circle',
        symbolSize: 2,
        name: "2.6m/s",
        markLine: showTakeoff ? {
          silent: true,
          data: [{
            xAxis: 65,
            label: { 
              formatter: 'take off',
              fontSize: 8,
              position: 'insideEndTop'
            }
          }]
        } : undefined,
      }
    ],
    legend: {
      show: true,
      orient: 'vertical',
      right: 10,
      top: 10,
      itemWidth: 15,
      itemHeight: 1,
      textStyle: {
        fontSize: 8,
        color: '#000'
      },
      formatter: name => {
        // Add a dash before each speed value
        return '- ' + name;
      },
      data: ['2.6m/s', '2.1m/s', '1.6m/s', '1.1m/s', '0.5m/s']
    },
    grid: {
      left: "15%",
      right: "15%",
      top: "20%",
      bottom: "15%",
    },
  });

  return (
    <div style={{ padding: "20px" }}>
      <h6 style={{color: " #1e1e2f" , textAlign:"center"}}> JOINT KINETICS IN HUMAN BODY</h6>
      {chartsData.map((chart, index) => (
        <div
          key={index}
          style={{ 
            display: "flex", 
            justifyContent: "center", 
            marginBottom: "5px",
            gap: "1px"
          }}
        >
          <div style={{ width: "45%" }}>
            <ReactECharts
              option={generateChartOptions(
                "Torque [Nm/kg]",
                `${chart.name}`,
                chart.torqueData,
                chart.torqueYAxis
              )}
              style={{ height: "120px", width: "100%", border:'1px solid #1e1e2f', borderRadius:'20px' }}
            />
          </div>

          <div style={{ width: "45%" }}>
            <ReactECharts
              option={generateChartOptions(
                "Power [W/kg]",
                `${chart.name}`,
                chart.powerData,
                chart.powerYAxis,
                true
              )}
              style={{ height: "120px", width: "100%", border:'1px solid #1e1e2f', borderRadius:'20px' }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default JointKineticsCharts;